import React from 'react'
import Form from '../components/Form'
const Contact = () => {
  return (
    <div>
    <Form/>
    </div>
  )
}

export default Contact