import React from 'react'
import PicGallery from '../components/PicGallery'

const Gallery = () => {
  return (
    <div>

    
    <PicGallery/>
    </div>
  )
}

export default Gallery